import React, { useEffect, useState } from 'react'
import './MintSection.scss';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import { toast } from 'react-toastify';
import loading from '../../assets/images/loading.svg'


import downImg from '../../assets/images/down-icon.png'
import whiteListAddresses from '../../addresses.json'
import MerkleTree from 'merkletreejs'
import keccak256 from 'keccak256'
import cubImg from '../../assets/images/bg-img.png'



import walletImg from '../../assets/images/walletImg.png'
import Web3EthContract from "web3-eth-contract";



function MintSection() {

  const truncate = (input, len) =>
     input.length > len ? `${input.substring(0, len)}...` : input;
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const [claimingNft, setClaimingNft] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [paused,setPaused] = useState('')
  const [cost,setCost] = useState('')
  const [maxPerWallet,setMaxPerWallet]=useState('');
  const[limit,setLimit]=useState('')
  const[totalSupply,setTotalSupply]=useState('')
  const[refresh,setRefresh]=useState(0)
  const[ethereum,setEthereum]=useState(null)
  const[balanceExceeded,setBalanceExceeded]=useState('')
  const[balance,setBalance]=useState('')
  const[addressWhitelisted,setAddressWhitelisted]=useState('')


  const[publicActive,setPublicActive]=useState('')
  const[presaleActive,setPresaleActive]=useState('')

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
 


  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    if(paused===true){
      toast.error('Mint coming soon')
      return;
    }
    if(claimingNft||cost==='')return;
    
    let cost2 = cost;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);


    const whiteListAddressesLeaves = whiteListAddresses.map((x) => keccak256(x))
    const merkletree = new MerkleTree(whiteListAddressesLeaves, keccak256 ,{sortPairs:true})
    const merklePoof=merkletree.getHexProof(keccak256(blockchain.account))

    toast.success(`Minting your ${mintAmount > 1 ? 'OTW NFTs' : 'OTW NFT'}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(merklePoof ,mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        toast.error("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        toast.success(() =>
          <span>
            OTW Mint Successful! Visit <a href="https://opensea.io/collection/out-this-world-official/">opensea.io</a> to view it.
          </span>
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
        setRefresh(refresh+1)
        setMintAmount(1)
      });
  };


  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();

  }, []);

  useEffect(() => {
    getData();

  }, [blockchain.account]);

  const mint = () => {
    claimNFTs();
    getData();
  }

  const connectWallet=()=>{
    // if(paused===''&&ethereum===null)return;
    dispatch(connect());
    getData();
  }

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > limit) {
      newMintAmount = limit;
    }
    if(limit===''){
      newMintAmount=1
    }
    if(limit===0){
      newMintAmount=1

    }
    setMintAmount(newMintAmount);
  };

  

  const setAmountInput = (amount) => {
    amount = Number(amount)
    if (isNaN(amount)) return;
    if (amount > limit) {
      setMintAmount(limit)
    }
    else {
      setMintAmount(amount)
    }
  }

  const initConnect=async()=>{
    // const { ethereum } = window;
    let ethereum=window?.ethereum?.providers!==undefined?window?.ethereum?.providers?.find((x) => x?.isMetaMask):window.ethereum
    // let walletProvider = window.ethereum.providers.find((x) => x.isCoinbaseWallet) // isCoinbaseWallet is part of the injected provider
    Web3EthContract.setProvider(ethereum);

  

    const address=CONFIG.CONTRACT_ADDRESS
    const abiResponse = await fetch("/config/abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();

    const SmartContractObj = new Web3EthContract(
      abi,
      address
    );
     
      // console.log(ethereum.isMetaMask)
    
    if(ethereum){
      ethereum.on("accountsChanged", (accounts) => {
        setRefresh(refresh+1)
      });
      ethereum.on("chainChanged", () => {
        window.location.reload();
      });
  
      try{
        const presaleCost=await SmartContractObj.methods.presaleCost().call();

        const publicSaleCost=await SmartContractObj.methods.publicSaleCost().call();
        const maxPerPresaleWallet=await SmartContractObj.methods.maxMintAmountPerPresaleAccount().call()

        const maxPerPublicSaleWallet=await SmartContractObj.methods.maxMintAmountPerPublicAccount().call()
        const presaleActive=await SmartContractObj.methods.presaleActive().call()
  
        const paused=await SmartContractObj.methods.paused().call();
        const totalSupply=await SmartContractObj.methods.totalSupply().call();
        // console.log(presaleActive)
        if(presaleActive){
          setCost(presaleCost)
          setMaxPerWallet(maxPerPresaleWallet)
          setPresaleActive(true)
          setPublicActive(false)
        }
        else {
          setCost(publicSaleCost)
          setMaxPerWallet(maxPerPublicSaleWallet)
          setAddressWhitelisted(true)
          setPublicActive(true)
        }
      
        setTotalSupply(Number(totalSupply))
      
        setPaused(paused)
    
      }
      catch(err){
        console.log(err)
      }
      
      // setEthereum(ethereum)
    }
    else{
      // toast.error('Install metamask!')
    }
   
  }

  useEffect(()=>{
    if(CONFIG.CONTRACT_ADDRESS==='')return;

    initConnect()

  },[refresh,CONFIG])

  useEffect(()=>{
    if(blockchain.account===null||maxPerWallet==='')return;

    blockchain.smartContract.methods.balanceOf(blockchain.account).call().then((result)=>{
      let balance=Number(result);
      setBalance(Number(result));
      let limit=Number(maxPerWallet)-result;
      if(limit<=0){
        setLimit(0)

      }
      else{
        setLimit(limit)

      }

    })

  },[refresh,blockchain.smartContract,maxPerWallet])


  useEffect(()=>{
    if(blockchain.account===null)return;

    const whiteListAddressesLeaves = whiteListAddresses.map((x) => keccak256(x));
    const merkletree = new MerkleTree(whiteListAddressesLeaves, keccak256 ,{sortPairs:true})
    const merklePoof=merkletree.getHexProof(keccak256(blockchain.account));
    const rootNode=merkletree.getRoot().toString('hex');
    let whitelisted=merkletree.verify(merklePoof,keccak256(blockchain.account),rootNode)

    //we check for totalSupply to check if the values have been retrieved
    if(whitelisted===false&&presaleActive!==''){
      setAddressWhitelisted(false)
    }
    else if(presaleActive!==''){
      setAddressWhitelisted(true)
    }
  },[blockchain.account,presaleActive])


  useEffect(()=>{
    connectWallet()
  },[])

  // console.log(addressWhitelisted)
  // console.log(presaleActive)
  console.log('initall')
  return (
    <div className='MintSection'>
     

      {blockchain.account&&
      <div className='connected-address text-white'>
        <span className='text-white font-s weight-bold'>Connected As</span>
        <span className='text-white font-xs'> {truncate(blockchain.account,15)}</span>
      </div>}
      <div className='mint-section-inner'>
        <span className='font-xls family-raleway weight-extra-bold text-white'>Out This World NFT Collection</span>
        <p className='font-xs family-raleway text-white'>Price Per NFT : {cost&&(cost/1000000000000000000)}ETH per NFT + gas fee</p>
        <p className='font-xs family-raleway text-white'>Limit: {maxPerWallet} per wallet</p>
        <p className='font-xs family-raleway text-white'>{totalSupply}/10000</p>

        <div className='mint-section-inner-bottom '>
          <div className='mint-input-div'>
            <button onClick={()=>{decrementMintAmount()}} className='amount-button font-xl family-raleway text-white'>
              -
            </button>
            <input className='font-l family-raleway text-white' value={mintAmount} onChange={(e)=>{setAmountInput(e.target.value)}}/>
            <button onClick={()=>{incrementMintAmount()}} className='amount-button font-xl family-raleway text-white'>
              +
            </button>
          </div>
         
          {
            (blockchain.smartContract===null||blockchain.account===null)&&
            (<button onClick={()=>{connectWallet()}} className='font-l mint-button family-raleway text-white weight-bold'>
              Connect Wallet
            </button>)
          }
          {
            blockchain.smartContract!==null&&addressWhitelisted===true&&limit>0?
            (
                
              <button onClick={()=>claimNFTs()} className='font-m mint-button family-raleway text-white weight-bold'>
                  {claimingNft===true?(<img src={loading}/>):'MINT NOW'}
              </button>

            )
            :addressWhitelisted===false?
            <div className='text-red font-m family-raleway weight-bold'>
              {blockchain.account!==null&&
                <div style={{textAlign:'center',paddingTop:'25px'}}>
                  <div>
                    Not Whitelisted!
                  </div>
                </div>
              }

              
              
              </div>
              :limit===0?
              <div className='text-red font-s family-raleway weight-bold'>
                {blockchain.account!==null&&
                  <div style={{textAlign:'center',paddingTop:'25px'}}>
                    <div>
                      Mint Limit Exceeded
                    </div>
                  </div>
              
                }
              </div>
              :blockchain.account!==null?
              <button  className='font-xs mint-button family-raleway'>
                <img src={loading}/>
              </button>:null
          }
        </div>
      </div>
    </div>
  )
}

export default MintSection
