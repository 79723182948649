import './App.scss';
import { ToastContainer } from 'react-toastify';

import "react-toastify/dist/ReactToastify.css";

import MintSection from './components/mint-section/MintSection'

import astronautImg from './assets/images/astronautImg.png'

function App(){
 

  return (
    <div className="App">
      <ToastContainer style={{top:'75px'}}  position="top-right" autoClose={5000} closeOnClick />
     
      <MintSection/>
      <img src={astronautImg} className='astronaut-img'/>
    </div>
  );
}

export default App;
